import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    this.element.classList.add('reference-object')
  }

  highlightElement(event) {
    if (!this.element?.id) return

    const targetElements = document.querySelectorAll(`[class^="${this.element.id}"]`)
    if (!targetElements.length) return

    const isHighlighting = event.type === 'mouseenter'
    const action = isHighlighting ? 'add' : 'remove'

    targetElements.forEach(element => element.classList[action]('highlight'))
  }
}
