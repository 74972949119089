import { Controller } from "@hotwired/stimulus"
let debounce = require('lodash/debounce')

// Connects to data-controller="invoice-target-calculation"
export default class extends Controller {
  static targets = [ "input", "output" ]
  static values = {
    invoiceDate: String
  }
  connect() {
    if (!this.hasInputTarget) return

    this.pattern = new RegExp(this.inputTarget.getAttribute('pattern'))
    this.inputTarget.form.addEventListener('input', (event) => { event.target.reportValidity(this.inputTarget.form) })
    this.inputTarget.addEventListener('keyup', debounce(this.calculateTargetDate.bind(this), 300))
  }

  updateOutput(targetDate) {
    if (!targetDate) {
      this.outputTarget.textContent = "Invalid date"
      return
    }
    this.outputTarget.textContent = targetDate
  }

  calculateTargetDate() {
    const targetDays = parseInt(this.inputTarget.value) || 0
    let currentDate = new Date(this.invoiceDateValue)
    if (isNaN(currentDate.getTime())) {
      console.error("Invalid invoice date:", this.invoiceDateValue)
      return
    }

    let newTargetDate = new Date(currentDate.setDate(currentDate.getDate() + targetDays))
    this.updateOutput(newTargetDate.toLocaleDateString())
  }
}
