import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="interest-calculator-deposits"
export default class extends Controller {
  static targets = ['depositDateSource', 'depositAmountSource', 'depositTemplate', 'depositsList']
  connect() {
  }

  addDeposit(event) {
    if (!this.depositAmountSourceTarget.value) {
      this.depositAmountSourceTarget.classList.add('is-invalid')
      alert('Please enter a deposit amount')
      this.depositAmountSourceTarget.focus()
      return
    }

    this.depositAmountSourceTarget.classList.remove('is-invalid')
    const deposit = this.depositTemplateTarget.content.cloneNode(true)
    deposit.querySelector('input[name^="deposit_date"]').value = this.depositDateSourceTarget.value
    deposit.querySelector('input[name^="deposit_amount"]').value = this.depositAmountSourceTarget.value

    this.depositsListTarget.appendChild(deposit)
    this.depositDateSourceTarget.value = ''
    this.depositAmountSourceTarget.value = ''
  }

  removeDeposit(event) {
    event.preventDefault()
    event.target.closest('.deposit').remove()
  }
}
