import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-submit"
export default class extends Controller {
  static values = {
    pressEnterToSubmit: { type: Boolean, default: false }
  }

  connect() {
    if (this.pressEnterToSubmitValue) {
      this.boundHandleKeyPress = this.handleKeyPress.bind(this)
      this.element.addEventListener("keypress", this.boundHandleKeyPress)
    }
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.element.requestSubmit()
    }
  }

  disconnect() {
    if (this.pressEnterToSubmitValue) {
      this.element.removeEventListener("keypress", this.handleKeyPress)
    }
  }

  resetForm() {
    this.element.reset()
  }
}
