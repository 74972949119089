import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static values = {
    delay: { type: Number, default: 200 }
  }

  connect() {
    this.submit()
  }

  submit() {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      const form = this.element.closest('form')
      if (form) {
        form.requestSubmit()
      }
    }, this.delayValue)
  }

}
