import { Controller } from "@hotwired/stimulus"
import Popover from "bootstrap/js/dist/popover"

/**
 * PopoverController is a Stimulus controller that manages Bootstrap popovers.
 * It initializes the popover with a custom allow list for HTML sanitization
 * and optionally adds functionality to close the popover when clicking outside of it.
 */
export default class extends Controller {
  /**
   * Connects the controller to the DOM element.
   * Initializes the popover with custom HTML sanitization rules.
   * Adds event listeners to close the popover when clicking outside, if specified.
   */
  connect() {
    // allow custom elements in popover
    // further info: https://getbootstrap.com/docs/5.3/getting-started/javascript/#sanitizer
    const myDefaultAllowList = Popover.Default.allowList

    // To allow table elements
    myDefaultAllowList.table = []
    // To allow thead elements
    myDefaultAllowList.thead = []
    // To allow tbody elements
    myDefaultAllowList.tbody = []
    // To allow tr elements
    myDefaultAllowList.tr = []
    // To allow th elements
    myDefaultAllowList.th = []
    // To allow td elements
    myDefaultAllowList.td = []

    this.popover = new Popover(this.element, {
      sanitize: true,
      html: true,
      allowList: myDefaultAllowList
    })

    if (!this.element.dataset.bsCloseOnClickOutside) return
    if (this.element.dataset.bsCloseOnClickOutside !== 'true') return

    this.addCloseOnClickOutside()
  }

  /**
   * Disconnects the controller from the DOM element.
   * Cleans up event listeners and destroys the popover instance.
   */
  disconnect() {
    // Clean up event listeners
    document.removeEventListener('keydown', this.handleKeydown)
    document.removeEventListener('click', this.handleClickOutside)

    // Destroy popover
    this.popover.dispose()
  }

  /**
   * Adds event listeners to close the popover when clicking outside of it
   * or pressing the Escape key.
   */
  addCloseOnClickOutside() {
    // Store bound methods as instance properties so they can be removed later
    this.handleKeydown = (event) => {
      if (event.key === 'Escape') {
        this.popover.hide()
      }
    }

    this.handleClickOutside = (event) => {
      const isClickInsidePopover = event.target.closest('.popover')
      const isClickOnTrigger = event.target === this.element

      if (!isClickInsidePopover && !isClickOnTrigger) {
        this.popover.hide()
      }
    }

    document.addEventListener('keydown', this.handleKeydown)
    document.addEventListener('click', this.handleClickOutside)
  }
}
