import { Controller } from "@hotwired/stimulus"
let debounce = require('lodash/debounce')

// Connects to data-controller="decimal-input-formatter"
export default class extends Controller {
  /**
   * Initializes the controller, sets the locale, defines regular expressions for German and English decimal formats,
   * and attaches a debounced event listener to the input element.
   * Based on the <html lang=""> lang attribute, the locale is set, defaults to 'de'.
   * Formats 123456789.99 to 123.456.789,99 when the locale is de.
   * Formats 123456789.99 to 123,456,789.99 when the locale is other than de.
   *
   * Before form submission the value is converted back to a non formatted value e.g. 123456789.9 to ensure
   * that params contains a valid value for further processing.
   *
   * Please note that this is using parseFloat which is limited to 15-17 digits.
   * Please note also that there are some edge cases where the formatter might not
   * work as expected. Feel free to adjust the regex accordingly.
   */
  connect() {
    const htmlLang = document.querySelector('html').getAttribute('lang')
    const jsDebugMode = document.querySelector('html').getAttribute('data-js-debug-mode')
    this.locale = htmlLang ? htmlLang : 'de'
    this.jsDebugMode = jsDebugMode ? jsDebugMode : 'silent'
    this.germanDecimalNumberRegex = /^-?(\d{1,3}(\.\d{3})*|\d+)(,\d+)?$/  // matches 1.234,56 or 1234,56
    this.englishDecimalNumberRegex = /^-?(\d{1,3}(,\d{3})*|\d+)([.]\d+)?$/ // matches 1,234.56 or 1234.56

    this.element.addEventListener("keydown", debounce(this.formatInput.bind(this), 500))
  }

  /**
   * Formats the input value based on the detected locale.
   * If the input matches the German format, it replaces dots with nothing and commas with dots.
   * If the input matches the English format, it removes commas.
   * It then parses the formatted value to a float and formats it according to the html locale.
   *
   * @param {Event} event - The input event triggered by the user.
   */
  formatInput(event) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ',', '-'];
    if (!allowedKeys.includes(event.key)) return;

    if (event.target.value === '') return

    this.log('> html locale', this.locale)
    this.log('> received input', event.target.value)
    let tmpValue = event.target.value

    if(this.germanDecimalNumberRegex.test(tmpValue)) {
      this.inputLocale = 'de'
    } else if(this.englishDecimalNumberRegex.test(tmpValue)) {
      this.inputLocale = 'en'
    } else {
      alert('Invalid number format')
      return
    }

    if (this.inputLocale === 'de') {
      this.log('> locale of input is de, replacing , with .')
      tmpValue = tmpValue.replace(/\./g, '').replace(/,/g, '.')
    } else {
      this.log('> locale of input is en, removing ,')
      tmpValue = tmpValue.replace(/,/g, '')
    }

    this.log('> unformatted input', tmpValue)
    event.target.dataset.unformattedValue = tmpValue

    // make the form aware to replace the localized value with the unformatted value before submitting
    event.target.closest('form')?.addEventListener('submit', this.submitForm.bind(this))

    let formattedValue = parseFloat(tmpValue)
    this.log('> parsed input', formattedValue)
    if (!isNaN(formattedValue)) {
      let localizedValue = Intl.NumberFormat(this.locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 15
      }).format(formattedValue)
      this.log('> localized value', localizedValue)
      event.target.value = localizedValue
    } else {
      this.log('not a number', tmpValue)
    }
  }

  /**
   * Handles the form submission by setting the input values to their unformatted versions.
   *
   * @param {Event} event - The submit event triggered by the form.
   */
  submitForm(event) {
    event.preventDefault()

    event.target.querySelectorAll('input[data-controller="decimal-input-formatter"]').forEach((input) => {
      this.log('unformat input from', input.value)
      this.log(' > to', input.dataset.unformattedValue)
      input.value = input.dataset.unformattedValue
    })

    event.target.submit()
  }

  /**
   * Helper method to log messages when jsDebugMode is 'verbose'.
   * This happens in the layout file (_base.html.haml).
   * @param  {...any} args - The messages or objects to log.
   */
  log(...args) {
    if (this.jsDebugMode === 'verbose') {
      console.log(...args)
    }
  }
}
