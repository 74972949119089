import { Application } from '@hotwired/stimulus'
import Datatable from 'stimulus-datatables'

const application = Application.start()

// we use these components out of the box and unchanged
// importing these components in controllers/index.js would remove then when running
// bin/rails stimulus:manifest:update since there is no controller for them
application.register('datatable', Datatable)
require('datatables.net-select-bs5')

import Clipboard from '@stimulus-components/clipboard'
application.register('clipboard', Clipboard)

import TextareaAutogrow from 'stimulus-textarea-autogrow'
application.register('textarea-autogrow', TextareaAutogrow)

import AutoSubmit from '@stimulus-components/auto-submit'
application.register('auto-submit', AutoSubmit)

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

import GuidedTourController from "@itsbede/guided-tour"
application.register('guided-tour--tour', GuidedTourController)

export { application }
