// Entry point for the build script in your package.json
require("@rails/ujs").start();

require("@hotwired/turbo-rails");
import { Turbo } from "@hotwired/turbo-rails"
import "./controllers";

require('bootstrap');
require("@fortawesome/fontawesome-free/js/all.min");

let jQuery = require('jquery')
window.$ = window.jQuery = jQuery;
// The controller will call: window.jQuery(table).DataTable(config)
import 'datatables.net'

// These examples use bootstrap4 and the scroller plugin.
// See https://datatables.net/download for more options.
import 'datatables.net-bs5'
import 'datatables.net-scroller-bs5'

import bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
// enable tooltips that do not yet use the respective stimulus controller
let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]:not([data-controller="tooltip"])'))
let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})

// enable popover that do not yet use the respective stimulus controller
const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]:not([data-controller="popover"])')
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => () => {
  if(popoverTriggerEl.dataset.controller === 'popover') return;

  new bootstrap.Popover(popoverTriggerEl)
})

// enable collapse functionality
const collapseElementList = document.querySelectorAll('.collapse')
// AND respect the initial state of the collapse element
const collapseList = [...collapseElementList].map(collapseEl => new bootstrap.Collapse(collapseEl, { toggle: collapseEl.classList.contains('show') } ))

// Adding a new custom action to Turbo Stream
// to use the new action to redirect to a new page from controller you have two options:
// 1. Rendering inline in a controller
//    => `render turbo_stream: turbo_stream.redirect(your_path)`
// 2. Using an *.turbo_stream.haml file
//    => `<turbo-stream action="redirect" url="<%= your_path %>"></turbo-stream>`
// 3. Using an *.turbo_stream.erb file
//    => <%= turbo_stream.redirect(your_path) %>
Turbo.StreamActions.redirect = function() {
  const url = this.getAttribute('url') || '/'
  // action: 'advance' will push a new entry to the history stack of the browser (like a redirect)
  Turbo.visit(url, { frame: '_top', action: 'advance' })
}
