import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="creditor-table"
export default class extends Controller {

  creditorTable = $('#open-positions-creditor-table').DataTable({
    "destroy": true,
    "columnDefs": [
      { "orderable": false, targets: 0 },
      { type: 'formatted-num', targets: 2, "class": "nowrap" },
      { type: 'formatted-num', targets: 3, "class": "nowrap" },
      { type: 'formatted-num', targets: 4, "class": "nowrap" },
      { type: 'formatted-num', targets: 5, "class": "nowrap" },
      { type: 'formatted-num', targets: 6, "class": "nowrap" }
    ],
    "order": [[ 3, "desc" ]],
    "lengthMenu": [[5, 20, 50, 100, 10000], [5, 20, 50, 100, 'Alle']],
    "stateSave": false,
    "searching": true,
    "ordering": true,
    "dom": '<"top"<"right"f>><"center"t><"d-flex justify-content-end align-items-baseline gap-4" <"me-auto"p>il>',
    "rowId": "DT_RowId",
    "language": {
      "emptyTable": "Es konnten keine Debitoren gefunden werden.",
      "lengthMenu": "_MENU_",
      "decimal": ",",
      "thousands": "."
    }
  });

  connect(){
    const dtObject = this.creditorTable

    $('#open-positions-creditor-table tbody').on('click', 'td.dt-control', function (e) {
      console.log('clicked on td dt-control');
      // This is the jQuery element for retrieving data from or adding/removing css classes
      let tr = $(this).closest('tr');
      // This is the DataTables row object for working with jQuery
      let row = dtObject.row(tr);
      let url = tr.attr('data-creditor-table-row-url-param');

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass('shown');
      } else {
        // Open this row
        $.ajax({
          url: url
        }).done(function( data ) {
          row.child( data ).show();
        });
        tr.addClass('shown');
      }
    });

    jQuery.extend( jQuery.fn.dataTableExt.oSort, {
      "formatted-num-pre": function ( a ) {
        a = (a === "-" || a === "") ? 0 : a.replace( /[^\d\-\,]/g, "" ).replace(',', '.');
        return parseFloat( a );
      },

      "formatted-num-asc": function ( a, b ) {
        return a - b;
      },

      "formatted-num-desc": function ( a, b ) {
        return b - a;
      }
    });
  }
}
